// AccountSettings.js
import React from "react";
import {
  Container,
  Title,
  Form,
  FormGroup,
  Label,
  Input,
  SaveButton,
} from "./accountSettingsStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme"; // Stelle sicher, dass du das richtige Theme importierst

const AccountSettings = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>Account Settings</Title>
        <Form>
          <FormGroup>
            <Label>Business Name</Label>
            <Input type="text" placeholder="Apple Inc" />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input type="email" placeholder="stevejobs@apple.com" />
          </FormGroup>
          <FormGroup>
            <Label>Contact Number</Label>
            <Input type="text" placeholder="+49 123 456789" />
          </FormGroup>
          <FormGroup>
            <Label>Address</Label>
            <Input type="text" placeholder="Gartenstr. 16" />
          </FormGroup>
          <FormGroup>
            <Label>Postcode</Label>
            <Input type="text" placeholder="12345" />
          </FormGroup>
          <FormGroup>
            <Label>Country</Label>
            <Input type="text" placeholder="Germany" />
          </FormGroup>
        </Form>
        <SaveButton>Save</SaveButton>
      </Container>
    </ThemeProvider>
  );
};

export default AccountSettings;
