export const theme = {
  colors: {
    primary: "#7c5cfc",
    primaryHover: "#6359cb",
    secondary: "#ffffff",
    secondaryText: "#242745",
    text: "#242745",
    mutedText: "#596780",
    border: "rgba(76, 78, 100, 0.12)",
    placeholder: "#c5c6c9",
    error: "#ff0000",
    success: "#00ff00",
    checkboxBorder: "rgba(76, 78, 100, 0.6)",
    comingSoonOverlay: "rgba(255, 255, 255, 0.8)",
    background: "#f3f5f7",
    hoverBackground: "#e1e3e7",
    menuTitle: "#90a3bf",
    buttonHover: "#6359cb", // Für den Hover-Zustand des Buttons
    buttonDisabled: "#c5c6c9", // Für deaktivierte Buttons
    white: "#ffffff", // Zusätzliche Farbe für allgemeine weiße Hintergründe
    black: "#000000", // Zusätzliche Farbe für Texte oder Icons
  },
  fonts: {
    primary: '"Plus Jakarta Sans", sans-serif',
  },
  fontSize: {
    xsmall: "12px", // Kleinere Schriftgröße für sekundäre Texte
    small: "14px", // Standard-Schriftgröße für Beschriftungen und sekundäre Texte
    medium: "16px", // Haupt-Schriftgröße für normalen Text
    large: "20px", // Größere Schriftgröße, z.B. für Untertitel
    xlarge: "24px", // Extra große Schriftgröße, z.B. für Titel
  },
  spacing: {
    xsmall: "4px", // Für kleine Abstände
    small: "8px", // Standard-Abstand
    medium: "16px", // Moderater Abstand
    large: "24px", // Großer Abstand
    xlarge: "32px", // Extra großer Abstand
  },
  borderRadius: {
    small: "4px", // Kleine Eckenrundung
    medium: "8px", // Standard-Eckenrundung für Buttons, Cards etc.
    large: "12px", // Größere Eckenrundung, wenn nötig
  },
  boxShadow: {
    light: "0 2px 10px rgba(0, 0, 0, 0.1)", // Leichter Schatten, z.B. für Karten
    heavy: "0 4px 20px rgba(0, 0, 0, 0.2)", // Schwerer Schatten für hervorgehobene Elemente
  },
};
