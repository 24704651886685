// accountSettingsStyle.js
import styled from "styled-components";

// Verwende das theme für Farben, Schriftarten und Abstände
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.large};
  text-align: left;
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  width: calc(50% - 10px);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: medium;
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.colors.mutedText};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: medium;
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.colors.text};

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`;

export const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.large};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.large};
  align-self: flex-start;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
